import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {fetchTeams} from 'actions/teams.js';
import {getTeams} from 'reducers/teams.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Teams from './Teams.js';


class TeamsContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      showTeamAssignModal:false,
                      teamToEdit:null,
                      unassignedEmployees: []};
        this.onShowEditTeam   = this.onShowEditTeam.bind(this);
        this.onShowTeamAssign = this.onShowTeamAssign.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onShowTeamDetails = this.onShowTeamDetails.bind(this);
        this.onChangeTeam = this.onChangeTeam.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData(true);
    }

    onChangeTeam(employee, teamId) {
        // this.setState(state=>{
        //     var unassignedEmployeesCount = state.unassignedEmployeesCount;
        //     var unassignedEmployees = state.unassignedEmployees.filter(e => e.id!==employee.id);
        //     return {unassignedEmployeesCount:unassignedEmployeesCount-1,
        //             unassignedEmployees:unassignedEmployees
        //            }; 
        // });
        DatabaseService.addTeamEmployee({
            teamId: teamId,
            companyEmployeeId: employee.id 
        })
        .then(()=>{
            this.loadData(false);
        })
        .catch((error)=> {
            window.alert('An error occurred - check logs');
            console.error(error);
        })
        .then(()=>{
        });
    }

    loadData(spinner, forceLoadTeams) {
        spinner && this.setState({busy:true});
        return Promise.all([
                // DatabaseService.getEmployees({
                //     unassigned : true,
                //     "summary.total" : true,
                //     limit : 1000 }),
                this.props.fetchTeams({"count":true}, forceLoadTeams)
            ])
            .then(([empResult, teamResult]) => {
                // this.setState({
                //     unassignedEmployees: empResult.employees,
                //     unassignedEmployeesCount: empResult.summary.total
                // });
            })
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
               spinner && this.setState({busy:false});
            });

    }

    onShowEditTeam(team) {
        this.setState({teamToEdit:team});
    }

    onShowTeamAssign(en) {
        this.setState({showTeamAssignModal:en});
    }

    onRefresh() {
        return this.loadData(false);
    }

    onShowTeamDetails(teamId) {
        this.props.history.push('/teams/details/'+teamId);
    }

    render() {
        return (
            <Teams busy={this.state.busy}
                       {...this.props}
                       {...this.state}
                       onShowEditTeam={this.onShowEditTeam}
                       onShowTeamAssign={this.onShowTeamAssign}
                       onRefresh={this.onRefresh}
                       onShowTeamDetails={this.onShowTeamDetails}
                       onChangeTeam={this.onChangeTeam}/>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {teams:getTeams(state.teams)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTeams: (params, force) => dispatch(fetchTeams(params, force)),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('teams')(TeamsContainer)));
